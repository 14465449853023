import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { OptService } from "../../../services/opt.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25OptConst } from "../s25.opt.const";
import { jSith } from "../../../util/jquery-replacement";
import { Proto } from "../../../pojo/Proto";
import { TelemetryService } from "../../../services/telemetry.service";

type Mode = Proto.NumericalBoolean | "edit";

interface Option {
    label: string;
    value: Mode;
}

@TypeManagerDecorator("s25-ng-opt-mode-dropdown")
@Component({
    selector: "s25-ng-opt-mode-dropdown",
    template: `
        <div class="ngOptElementPad ngInlineBlock">
            <select
                class="ngOptModeDropdown ng-pristine ng-valid ng-not-empty ng-touched cn-form__control"
                [(ngModel)]="mode"
                (ngModelChange)="changeMode($event)"
                aria-label="Select View Mode"
            >
                @for (item of options; track item) {
                    <option [ngValue]="item.value" [disabled]="item.value === 'edit' && vizType > 0">
                        {{ item.label }}
                    </option>
                }
            </select>
        </div>
    `,
    styles: `
        select.cn-form__control {
            line-height: 1em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptModeDropdownComponent implements OnInit {
    @Input() vizType: number;
    @Input() editable: boolean;
    @Input() separated: Proto.NumericalBoolean;
    @Input() hasEditableCheckbox: boolean;
    @Input() loggedIn: boolean;
    @Output() onEditableChange = new EventEmitter<boolean>();
    @Output() onSeparatedChange = new EventEmitter<Proto.NumericalBoolean>();
    @Output() modeChange = new EventEmitter<"overlapping" | "separated" | "edit">();

    mode: Mode;
    options: Option[];

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.angBridge = this; // Needed for tests
    }

    ngOnInit() {
        this.mode = this.editable ? "edit" : this.separated;
        this.options = S25Util.copy([], S25OptConst.modeOptions);
        if (this.hasEditableCheckbox) this.options.push({ label: "Edit Mode", value: "edit" });
    }

    async changeMode(mode: Mode) {
        let modeName: "edit" | "separated" | "overlapping" =
            mode === "edit" ? mode : Number(mode) ? "separated" : "overlapping";
        TelemetryService.send("Avail", S25Util.firstCharToUpper(modeName));
        this.modeChange.emit(modeName);
        if (mode === "edit") return this.changeEditable(true); // Enter edit mode
        if (this.editable) await this.changeEditable(false); // If in edit mode, we need to exit it
        this.onSeparatedChange.emit(mode); // Set separated mode (0 or 1)
        if (this.loggedIn) return OptService.setSeparatedPreference(String(mode)); // If logged-in, save preference
    }

    changeEditable(editable: boolean) {
        if (editable && this.separated) {
            // If edit mode and separated, we need to exit separated mode
            this.separated = 0;
            this.onSeparatedChange.emit(this.separated);
        }
        this.editable = editable;
        this.onEditableChange.emit(editable);
        return new Promise((resolve) => jSith.timeout(() => resolve(undefined))); // Wait for editable to be set, then refresh component
    }
}
