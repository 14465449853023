import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ColorBucketType, ItemColorMappingService } from "../../../services/item.color.mapping.service";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-opt-avail-legend-button")
@Component({
    selector: "s25-ng-opt-avail-legend-button",
    template: `
        @if (isInit) {
            <a
                (click)="openModal(legendModal)"
                (keyup.enter)="openModal(legendModal)"
                class="aw-button aw-button--outline"
                tabindex="0"
            >
                Legend
            </a>
        }
        <s25-ng-modal #legendModal [title]="'Availability Color Legend'" [size]="'xs'">
            <ng-template #s25ModalBody>
                <ul class="avail-legend">
                    <li class="ngAvailTypeId1" [tabindex]="0">Default Event Green</li>
                    <li class="ngAvailTypeId5" [tabindex]="0">Related Event Orange</li>
                    <li class="ngAvailTypeId4" [tabindex]="0">Pending Event Purple</li>
                    <li class="ngAvailTypeId7" [tabindex]="0">Requested Event (Color May Vary)</li>
                    <li class="ngAvailTypeId8" [tabindex]="0">Draft Event (Color May Vary)</li>
                    <li class="ngAvailTypeId2" [tabindex]="0">Blackout Grey</li>
                    <li class="ngAvailTypeId3" [tabindex]="0">Closed Hours Grey</li>
                    @for (bucket of bucketType.buckets; track bucket) {
                        <li
                            [tabindex]="0"
                            [ngStyle]="{
                                backgroundColor: bucket.color.hash,
                                color: bucket.color.textColor,
                                backgroundImage: bucket.color.pattern?.css,
                            }"
                        >
                            {{ bucket.bucket_name }} {{ bucket.color.name }}
                        </li>
                    }
                </ul>
            </ng-template>
        </s25-ng-modal>
    `,
    styles: `
        :host {
            align-self: flex-end;
        }

        .ngAvailTypeId2 {
            color: white;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptAvailLegendButtonComponent implements OnInit {
    bucketType: ColorBucketType;
    isInit = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.bucketType = await ItemColorMappingService.getEnabledBucketType();
        // Need to remove the ";" off any CSS patterns
        for (let bucket of this.bucketType?.buckets?.filter((bucket) => bucket.color.pattern?.css) || []) {
            bucket.color.pattern.css = bucket.color.pattern.css.replace(/;$/, "");
        }
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    openModal(modal: S25ModalComponent) {
        return modal.open();
    }
}
