import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { OptUtilI } from "./s25.opt.util";
import { ColumnChooserModelI } from "../s25-column-chooser/s25.column.chooser.component";
import { OptService } from "../../services/opt.service";
import { SearchDropdownApi } from "../s25-dropdown/single-select/s25.search.dropdown.component";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { S25OptConst } from "./s25.opt.const";
import { FlsService } from "../../services/fls.service";
import { ObjectPermissionService } from "../../services/object.permission.service";
import { UserprefService } from "../../services/userpref.service";
import { DropDownItem } from "../../pojo/DropDownItem";
import { Item } from "../../pojo/Item";
import { S25OptDateOptionsComponent } from "./s25-opt-components/s25.opt.date.options.component";
import { AccessLevels, isMinFls } from "../../pojo/Fls";
import { S25Const } from "../../util/s25-const";
import { DaysOfWeekPref } from "../../services/preference.service";
import { Proto } from "../../pojo/Proto";
import { HelpTopic } from "../s25-help/s25.help.service";
import { Bind } from "../../decorators/bind.decorator";
import { SearchCriteria } from "../../pojo/SearchCriteria";

export interface OptBean extends OptUtilI {
    options?: any;
    lastCacheId?: number;
    cacheIdOverride?: number;
    multiQueryStr?: string;
    endDate?: Date;
    dateFormat?: string;
    timeFormat?: string;
    weekstart?: number; // 0 = Sunday
    chosenNumWeeks?: number;
    chosen?: { obj: DropDownItem };
    colChooseBean?: ColumnChooserModelI;
    datepicker?: { modelBean: { date: Date } };
    broadcast?: (event: string, data: any) => void; // TODO: Switch to @Output once parents are migrated
    canCreateTodo?: string | boolean;
    hasHelp?: boolean;
    loggedIn?: boolean;
    canEdit?: boolean;
    showRelatedLocations?: boolean;
    showRelatedEvents?: boolean;
    hideNoChargeItems?: boolean;
    hideViewerSeat?: boolean;
    includeRequested?: boolean;
    includeAddtl?: boolean;
    useCache?: boolean;
    useServiceCache?: boolean;
    canEditEvent?: boolean;
    showBlackouts?: boolean;
    snapToGrid?: boolean;
    combineRelatedEvents?: boolean;
    hasHideNoChargeItems?: boolean;
    hasHideViewerSeat?: boolean;
    updateDateArrow?: (type: "back" | "forward") => void; // Set by opt
    hasListVisualization?: boolean; // Defined in s25ql-search
    hasCalendarVisualization?: boolean; // Defined in s25ql-search
    hasAvailabilityVisualization?: boolean; // Defined in s25ql-search
    hasAvailabilityWeeklyVisualization?: boolean; // Defined in s25ql-search
    selectedViz?: VisualizationType; // Defined in s25ql-search
    visualizationClick?: (viz: VisualizationType) => void; // Defined in s25ql-search
    pages?: number[]; // Not sure if local or passed
    pageNum?: number; // Not sure if local or passed
    pageKey?: string; // Not sure if local or passed
    selectedView?: "main" | "occurrence";
    hasOpt?: boolean; //defined in List
    searchQuery?: string; //for bulkedit button
    searchCacheId?: number; //for bulkedit button
    objectId?: number; //for bulkedit button
    searchModel?: SearchCriteria.Model; //for bulkedit button
    totalSearchRowCount?: number; //for bulkedit button
    canBulkEdit?: boolean; // for bulkEdit button
}

export type VisualizationType = "list" | "calendar" | "availability" | "availabilityWeekly";
export type ViewButtonValue = "futureOnly" | "allDates" | "recentHistory";

@TypeManagerDecorator("s25-ng-opt")
@Component({
    selector: "s25-ng-opt",
    template: `
        @if (modelBean) {
            <div class="s25-ng ngWidth100 ngOptBar" ng-show="modelBean.hasOpt">
                @if (modelBean.hasOpt) {
                    <div>
                        <div class="ngOptSubContainer">
                            @if (showLoading) {
                                <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
                            }
                            @if (
                                modelBean.hasListVisualization ||
                                modelBean.hasCalendarVisualization ||
                                modelBean.hasAvailabilityVisualization ||
                                modelBean.hasAvailabilityWeeklyVisualization
                            ) {
                                <s25-ng-opt-visualization-buttons
                                    class="ngInlineBlock ngOptElementPad c-dateviews--small c-viz-container"
                                    [hasList]="modelBean.hasListVisualization"
                                    [hasCalendar]="modelBean.hasCalendarVisualization"
                                    [hasAvailability]="modelBean.hasAvailabilityVisualization"
                                    [hasAvailabilityWeekly]="modelBean.hasAvailabilityWeeklyVisualization"
                                    [selected]="modelBean.selectedViz"
                                    (vizChange)="onTabChange($event)"
                                ></s25-ng-opt-visualization-buttons>
                            }
                            @if (modelBean.hasEditToggle && (canEditInline || modelBean.canEditEvent)) {
                                <s25-ng-opt-edit-toggle-button
                                    class="ngOptElementPad ngInlineBlock canEditToggle"
                                    [itemTypeId]="modelBean.itemTypeId"
                                    (onToggle)="onEditToggle($event)"
                                ></s25-ng-opt-edit-toggle-button>
                            }
                            @if (!!modelBean.hasSearchChooser) {
                                <s25-ng-opt-search-chooser
                                    class="ngOptSectionPad ngInlineBlock c-optionsBar--search"
                                    [(chosen)]="modelBean.chosen.obj"
                                    (chosenChange)="onSearchChange($event)"
                                    [itemTypeId]="itemName2Id[modelBean.origCompsubject]"
                                    [loggedIn]="modelBean.loggedIn"
                                    [compType]="modelBean.comptype"
                                    (onDisableRelatedLocationsChange)="modelBean.disableRelatedLocations = $event"
                                    (onChange)="getData(false)"
                                ></s25-ng-opt-search-chooser>
                            }
                            @if (!!modelBean.hasMultiQuery) {
                                <s25-ng-opt-multi-query
                                    class="c-optionsBar--search ngInlineBlock ngOptSectionPad"
                                    [weekStart]="modelBean.weekstart"
                                    [compType]="modelBean.comptype"
                                    [compSubject]="modelBean.compsubject"
                                    [date]="modelBean.date"
                                    [endDate]="modelBean.endDate"
                                    [showRelatedLocations]="modelBean.showRelatedLocations"
                                    [showRelatedEvents]="modelBean.showRelatedEvents"
                                    [editable]="modelBean.editable"
                                    [includeRequested]="modelBean.includeRequested"
                                    [includeAdditional]="modelBean.includeAddtl"
                                    [itemTypeId]="modelBean.itemTypeId"
                                    (multiQueryStrChange)="onMultiSearchChange($event)"
                                    (onChange)="getData()"
                                    (hasMultiQueryChange)="modelBean.hasMultiQuery = $event"
                                ></s25-ng-opt-multi-query>
                            }
                            @if (!!modelBean.hasPagination && modelBean.pages) {
                                <s25-ng-opt-pages
                                    class="ngInlineBlock ngOptSectionPad hasPagination"
                                    [pages]="modelBean.pages"
                                    [pageNumber]="modelBean.pageNum"
                                    (onChange)="modelBean.pageNum = $event; usePagination = true; getData(false)"
                                ></s25-ng-opt-pages>
                            }
                            @if (!!modelBean.hasOfficeHoursSlider) {
                                <s25-ng-office-hours-slider
                                    class="ngOptOfficeHoursSlider"
                                    [hasLabel]="true"
                                    (onChange)="getData()"
                                ></s25-ng-office-hours-slider>
                            }
                            <s25-ng-opt-checkboxes
                                [hasBlackouts]="modelBean.hasBlackoutsCheckbox"
                                [hasRelatedEvents]="modelBean.hasRelatedEventsCheckbox"
                                [hasIncludeRequested]="modelBean.hasIncludeRequested && !modelBean.editable?.value"
                                [hasAdditionalTime]="modelBean.hasAddtlTime"
                                [hasRelatedLocations]="modelBean.hasRelatedLocationsCheckbox"
                                [hasCombineRelatedEvents]="modelBean.hasCombineRelatedEventsCheckbox"
                                [hasHideNoChargeItems]="modelBean.hasHideNoChargeItems"
                                [hasHideViewerSeat]="modelBean.hasHideViewerSeat"
                                [hasSnapToGrid]="modelBean.hasSnapToGrid"
                                [showBlackouts]="modelBean.showBlackouts"
                                [showRelatedEvents]="modelBean.showRelatedEvents"
                                [showIncludeRequested]="modelBean.includeRequested"
                                [showIncludeAdditional]="modelBean.includeAddtl"
                                [showRelatedLocations]="modelBean.showRelatedLocations"
                                [showCombineRelatedEvents]="modelBean.combineRelatedEvents"
                                [hideNoChargeItems]="modelBean.hideNoChargeItems"
                                [showSnapToGrid]="modelBean.snapToGrid"
                                [itemId]="modelBean.itemId"
                                [compType]="modelBean.comptype"
                                [disableRelatedLocations]="modelBean.disableRelatedLocations"
                                [editable]="modelBean.editable?.value"
                                (onChange)="modelBean[$event.key] = $event.value"
                                (getData)="getData()"
                                (includeRequestedChange)="includeRequestedChange.emit($event)"
                                (snapToGridChange)="snapToGridChange.emit($event)"
                            ></s25-ng-opt-checkboxes>
                            <div class="c-optionsBar__relatedWrapper ngInlineBlock dateOptionsContainer">
                                <!-- TODO: Passing modelBean here is temporary. It is needed for an injected JS modal -->
                                @if (modelBean.canRegister) {
                                    <s25-ng-register-button
                                        [itemId]="modelBean.itemId"
                                        [modelBean]="modelBean"
                                    ></s25-ng-register-button>
                                }
                                @if (modelBean.hasEditEvent) {
                                    <s25-ng-opt-edit-event-button
                                        [itemId]="modelBean.itemId"
                                        [itemTypeId]="modelBean.itemTypeId"
                                    ></s25-ng-opt-edit-event-button>
                                }
                                @if (modelBean.hasEventStateChange && modelBean.loggedIn) {
                                    <s25-ng-event-states
                                        class="ngInlineBlock ngOptElementPad ngOptStateChange c-inputItem c-optionsBar__state-select ngOptBumpUp"
                                        [itemId]="modelBean.itemId"
                                    ></s25-ng-event-states>
                                }
                                @if (modelBean.loggedIn && modelBean.hasCancelRequest) {
                                    <s25-ng-cancel-request-button
                                        [itemId]="modelBean.itemId"
                                    ></s25-ng-cancel-request-button>
                                }
                                @if (!!modelBean.hasDatePropertyDropdown) {
                                    <s25-ng-opt-group-calendar-dropdown
                                        [value]="modelBean.datePropertyValue"
                                        (onChange)="modelBean.datePropertyValue = $event; refreshData()"
                                    ></s25-ng-opt-group-calendar-dropdown>
                                }
                                @if (!!modelBean.hasDatepicker) {
                                    <s25-ng-opt-date-options
                                        [weekStart]="modelBean.weekstart"
                                        [(weeks)]="modelBean.chosenNumWeeks"
                                        [hasDatepicker]="modelBean.hasDatepicker"
                                        [hasVariableDatepicker]="modelBean.hasVariableDatepicker"
                                        [hasWeekDatepicker]="modelBean.hasWeekDatepicker"
                                        [hasDaysSelector]="modelBean.hasDaysSelector"
                                        [hasWeeksChooser]="modelBean.hasWeeksChooser"
                                        [hasDaysChooser]="modelBean.hasDaysChooser"
                                        [isCalendar]="modelBean.isCalendar"
                                        [date]="modelBean.date"
                                        [endDate]="modelBean.endDate"
                                        [dateFormat]="modelBean.dateFormat"
                                        [datesOptionValue]="modelBean.datesOption?.value"
                                        [skipDaysPref]="modelBean.skipDaysPref"
                                        (getData)="this.getData($event)"
                                        (onChange)="modelBean[$event.key] = $event.value"
                                        (onDatePickerChange)="onDatepickerChange($event)"
                                        (removeButtonClasses)="removeButtonClasses()"
                                        (daysChange)="daysChange.emit($event)"
                                        (endDateChange)="endDateChange.emit($event)"
                                    ></s25-ng-opt-date-options>
                                }
                            </div>
                            <div class="ngInlineBlock c-dateviews-container">
                                @if (modelBean.hasUtilViz) {
                                    <s25-ng-opt-util-visualization
                                        class="ngOptElementPad ngOptUtilViz"
                                        [editable]="modelBean.editable.value"
                                        [utilRateViz]="modelBean.utilRateViz.value"
                                        [choices]="modelBean.utilVizChoices"
                                        (onUtilRateVizChange)="onUtilRateVizChange($event)"
                                    ></s25-ng-opt-util-visualization>
                                }
                                @if (modelBean.hasDateViews && (modelBean.hasFutureOnly || modelBean.hasAllDates)) {
                                    <s25-ng-opt-date-view-buttons
                                        class="ngOptElementPad ngInlineBlock c-dateviews--small"
                                        [hasFutureOnly]="modelBean.hasFutureOnly"
                                        [hasRecentHistory]="modelBean.hasRecentHistory"
                                        [hasAllDates]="modelBean.hasAllDates"
                                        (onChange)="setDatesOption($event)"
                                    ></s25-ng-opt-date-view-buttons>
                                }
                                @if (!!modelBean.hasEventTaskTypeChooser) {
                                    <s25-ng-opt-task-type-chooser
                                        class="ngInlineBlock ngOptElementPad ngOptEventTask"
                                        [type]="modelBean.taskType"
                                        (onTypeChange)="modelBean.taskType = $event; refreshData()"
                                    ></s25-ng-opt-task-type-chooser>
                                }
                                <!-- TODO: do not pass whole colChooseBean -->
                                @if (modelBean.colChooseBean && !!modelBean.hasColumnChooser) {
                                    <s25-ng-column-chooser
                                        class="ngOptElementPad ngInlineBlock"
                                        [modelBean]="modelBean.colChooseBean"
                                    ></s25-ng-column-chooser>
                                }
                                @if (!!modelBean.hasCreateTodoButton && modelBean.canCreateTodo) {
                                    <s25-ng-opt-create-todo-button
                                        class="ngOptElementPad ngInlineBlock"
                                    ></s25-ng-opt-create-todo-button>
                                }
                                @if (!!modelBean.hasMode) {
                                    <s25-ng-opt-mode-dropdown
                                        [vizType]="modelBean.utilRateViz.value.type"
                                        [editable]="modelBean.editable.value"
                                        [separated]="modelBean.separated.value"
                                        [hasEditableCheckbox]="modelBean.hasEditableCheckbox"
                                        [loggedIn]="modelBean.loggedIn"
                                        (onEditableChange)="onEditableChange($event)"
                                        (onSeparatedChange)="
                                            modelBean.separated.value = $event;
                                            broadcast('s25OptSeparatedChanged', $event)
                                        "
                                        (modeChange)="modeChange.emit($event)"
                                    ></s25-ng-opt-mode-dropdown>
                                }
                                @if (modelBean.hasTimestamp && (modelBean.lastupdate || lastUpdate)) {
                                    <s25-ng-opt-last-update
                                        class="ngOptElementPad ngInlineBlock viewOptions_Datestamp c-padding-right-medium--none"
                                        [lastUpdate]="modelBean.lastupdate || lastUpdate"
                                        [timeFormat]="modelBean.timeFormat"
                                    ></s25-ng-opt-last-update>
                                }
                                @if (modelBean.hasAlwaysShareLocation) {
                                    <s25-ng-location-always-share
                                        class="ngOptElementPad ngInlineBlock loc-always-share--wrapper"
                                        [spaceId]="modelBean.itemId"
                                        [editModeOn]="spaceEditMode"
                                    ></s25-ng-location-always-share>
                                }
                                @if (!!securityLink) {
                                    <s25-security-link
                                        class="optSecurityLink"
                                        [itemTypeId]="modelBean.itemTypeId"
                                        [itemId]="modelBean.itemId"
                                    ></s25-security-link>
                                }
                                @if (!!modelBean.loggedIn && !!modelBean.hasAvailOptions) {
                                    <s25-ng-opt-avail-options-button
                                        class="ngOptElementPad ngInlineBlock ngOptBar--avail-wrapper"
                                        (modalClosed)="refreshData()"
                                    >
                                    </s25-ng-opt-avail-options-button>
                                }
                                @if (!!modelBean.hasAvailLegend) {
                                    <s25-ng-opt-avail-legend-button
                                        class="ngInlineBlock ngOptBar--avail-wrapper"
                                        [class.ngOptElementPad]="!modelBean.hasAvailOptions"
                                    ></s25-ng-opt-avail-legend-button>
                                }
                                @if (hasBulkEdit && modelBean.canBulkEdit && modelBean.loggedIn) {
                                    <s25-ng-opt-bulk-edit-button
                                        [query]="modelBean.searchQuery"
                                        [cacheId]="modelBean.searchCacheId"
                                        [objectId]="modelBean.objectId"
                                        [searchModel]="modelBean.searchModel"
                                        [dateView]="modelBean?.datesOption?.value"
                                        [totalSearchRowCount]="modelBean.totalSearchRowCount"
                                    ></s25-ng-opt-bulk-edit-button>
                                }
                                @if (!!modelBean.hasRefresh) {
                                    <s25-ng-opt-refresh-button
                                        class="ngOptElementPad ngInlineBlock ngOptIcon"
                                        (onRefresh)="refreshData()"
                                    ></s25-ng-opt-refresh-button>
                                }
                                @if (modelBean.hasHelp && modelBean.helpTopic) {
                                    <s25-ng-opt-help-button
                                        class="viewOptions_Help ngOptElementPad ngOptIcon ngInlineBlock ngCpointer"
                                        [topic]="modelBean.helpTopic"
                                    ></s25-ng-opt-help-button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptComponent implements OnInit, OnChanges {
    @Input() modelBean: OptBean;
    @Input() date: Date;
    @Input() showLoading: boolean;
    @Input() dateFormat: string;
    @Input() snapToGrid: boolean;
    @Input() lastUpdate: Proto.ISODateString;
    @Input() hasListTab: boolean;
    @Input() hasCalendarTab: boolean;
    @Input() hasAvailDailyTab: boolean;
    @Input() hasAvailWeeklyTab: boolean;
    @Input() selectedTab: VisualizationType;
    @Input() hasDatepicker: boolean;
    @Input() hasVariableDatepicker: boolean;
    @Input() hasDaysSelector: boolean;
    @Input() hasDateViews: boolean;
    @Input() selectedDateView: ViewButtonValue;
    @Input() hasRefresh: boolean;
    @Input() columnChooser: OptColumnChooser;
    @Input() hasCreateTodo: boolean;
    @Input() help: HelpTopic;
    @Input() hasBulkEdit?: boolean;

    @Output() modelValueChange = new EventEmitter();
    @Output() dateChange = new EventEmitter<Date>(); // Emits whenever the date changes from the date picker
    @Output() endDateChange = new EventEmitter<Date>(); // Emits whenever the date changes from the date picker
    @Output() dateViewChange = new EventEmitter<ViewButtonValue>();
    @Output() daysChange = new EventEmitter<DaysOfWeekPref>(); // Emits whenever days of weeks are picked
    @Output() includeRequestedChange = new EventEmitter<boolean>(); // Emits whenever the "Include Requested" checkbox is changed
    @Output() refreshed = new EventEmitter<void>(); // Emits when the refresh button is clicked
    @Output() modeChange = new EventEmitter<"overlapping" | "separated" | "edit">();
    @Output() snapToGridChange = new EventEmitter<boolean>();
    @Output() utilizationViewChange = new EventEmitter<OptUtilizationView>();
    @Output() queryChange = new EventEmitter<string>();
    @Output() tabChange = new EventEmitter<"availabilityWeekly" | "list" | "availability" | "calendar">();
    @Output() columnChooserChange = new EventEmitter<OptColumnChooser>();
    @Output() editModeChange = new EventEmitter<boolean>();
    usePagination: boolean;
    spaceEditMode: boolean;
    securityLink: boolean;
    canEditInline: boolean;

    itemName2Id = S25Const.itemName2Id;

    @ViewChild(S25OptDateOptionsComponent) dateOptionsComponent: S25OptDateOptionsComponent;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; // Needed for tests
    }

    ngOnChanges(changes: SimpleChanges) {
        this.detectModelBeanPropertyChanges();
        if (changes.date && this.modelBean) this.modelBean.date = this.date;
        if (changes.dateFormat && this.modelBean) this.modelBean.dateFormat = this.dateFormat;
        if (changes.snapToGrid && this.modelBean) this.modelBean.snapToGrid = this.snapToGrid;
        if (changes.modelBean && this.modelBean) {
            if (this.date) this.modelBean.date = this.date;
            if (this.dateFormat) this.modelBean.dateFormat = this.dateFormat;
            if (this.snapToGrid) this.modelBean.snapToGrid = this.snapToGrid;
        }
        if (changes.columnChooser) this.setColChooseBean();
    }

    // Detects changes which come from outside the Angular zone and Angular can't detect
    detectModelBeanPropertyChanges() {
        S25Util.detectPropertyChanges(this.modelBean, "lastupdate", this.changeDetector);
        S25Util.detectPropertyChanges(this.modelBean, "colChooseBean", this.changeDetector);
        S25Util.detectPropertyChanges(this.modelBean, "searchModel", this.changeDetector);
    }

    ngOnInit() {
        // Zone needed for datepicker ngb-popover to behave as expected (does not always show up without zone)
        return this.zone.run(async () => {
            if (!this.modelBean) return;
            const modelBean = this.modelBean;
            this.detectModelBeanPropertyChanges();

            if (modelBean.hasRelatedLocationsCheckbox) {
                // Watch disabled rel loc variable. Note if it is enabled (!disabled...) then we force compsubject to location
                // so that downstream data services us rm_reservations service and thus include=related actually includes related locations
                modelBean.compsubject = modelBean.disableRelatedLocations ? modelBean.origCompsubject : "location";
                S25Util.onPropertyChange(modelBean, "disableRelatedLocations", (newVal: boolean, oldVal: boolean) => {
                    if (newVal === oldVal) return;
                    modelBean.compsubject = newVal ? modelBean.origCompsubject : "location";
                });
            }

            this.modelBean.hasMultiQuery = S25Util.toBool(window.ProData?.embeddedConfig?.hasMultiQuery); // For embedded
            if (modelBean.hasSearchChooser) jSith.timeout(() => this.updateDropdown());
            if (this.hasCreateTodo) modelBean.hasCreateTodoButton = true;

            const [userPreferences, dateFormat, canEdit, fls, hasRegistration, timeFormat] = await Promise.all([
                modelBean.loggedIn ?? UserprefService.getUserPrefs(),
                modelBean.dateFormat ?? UserprefService.getS25Dateformat(),
                modelBean.canEdit ??
                    (modelBean.hasEditToggle &&
                        modelBean.itemTypeId &&
                        modelBean.itemId &&
                        ObjectPermissionService.canEdit(Number(modelBean.itemId), modelBean.itemTypeId)),
                (modelBean.hasCreateTodoButton || modelBean.hasSecurity || modelBean.hasRegister || this.hasBulkEdit) &&
                    FlsService.getFls(),
                this.modelBean.hasRegister && EventService.hasRegistration(this.modelBean.itemId),
                modelBean.timeFormat ?? UserprefService.getS25Timeformat(),
            ]);

            if (modelBean.hasCreateTodoButton)
                modelBean.canCreateTodo = fls?.CREATE_TODO && fls?.CREATE_TODO !== AccessLevels.None;
            if (modelBean.hasRegister)
                this.modelBean.canRegister = hasRegistration && fls && fls.EVENT_COMMERCE !== "N";
            if (modelBean.hasSecurity) this.initSecurity(canEdit, fls);
            if (!modelBean.loggedIn) {
                modelBean.weekstart = userPreferences.weekstart;
                modelBean.hasHelp = userPreferences.loggedIn;
                modelBean.loggedIn = userPreferences.loggedIn;
            }

            modelBean.dateFormat = dateFormat;
            modelBean.timeFormat = timeFormat;
            modelBean.canEdit = canEdit;
            this.canEditInline = canEdit;
            if (canEdit && modelBean.itemTypeId === Item.Ids.Event) {
                this.canEditInline = isMinFls(fls.WEBSECURITY, AccessLevels.Full);
            }
            modelBean.updateDateArrow = this.updateDateArrow;
            if (this.hasListTab) modelBean.hasListVisualization = true;
            if (this.hasCalendarTab) modelBean.hasCalendarVisualization = true;
            if (this.hasAvailDailyTab) modelBean.hasAvailabilityVisualization = true;
            if (this.hasAvailWeeklyTab) modelBean.hasAvailabilityWeeklyVisualization = true;
            if (this.selectedTab) modelBean.selectedViz = this.selectedTab;
            if (this.hasDatepicker) modelBean.hasDatepicker = true;
            if (this.hasVariableDatepicker) modelBean.hasVariableDatepicker = true;
            if (this.hasDateViews) {
                modelBean.hasDateViews = true;
                modelBean.hasRecentHistory = true;
                modelBean.hasAllDates = true;
                modelBean.hasFutureOnly = true;
                modelBean.datesOption = { value: this.selectedDateView };
            }
            this.setColChooseBean();
            if (this.hasRefresh) modelBean.hasRefresh = true;
            if (this.help) {
                modelBean.hasHelp = true;
                modelBean.helpTopic = this.help;
            }
            if (this.hasDaysSelector) modelBean.hasDaysSelector = true;

            if (this.hasBulkEdit) {
                this.modelBean.canBulkEdit =
                    (this.modelBean.objectId === 1 && fls?.WEBSECURITY === "F") ||
                    (this.modelBean.objectId === 4 && ["F", "C"].indexOf(fls?.SPACE_LIST) > -1) ||
                    (this.modelBean.objectId === 2 && ["F", "C"].indexOf(fls?.CU_ACCOUNT) > -1) ||
                    (this.modelBean.objectId === 6 && ["F", "C"].indexOf(fls?.RESOURCE_LIST) > -1) ||
                    (this.modelBean.objectId === 10 && ["F", "C"].indexOf(fls?.TASK_LIST) > -1);
            }

            await S25Util.delay(0); // Initialize first getData after list actually exists
            if (modelBean.autoInit) this.getData(true);
            this.changeDetector.detectChanges();
            this.setButtonClass(modelBean.datesOption?.value);
            if (modelBean.onlyInitOnce) modelBean.autoInit = false;
            this.changeDetector.detectChanges();
        });
    }

    initSecurity(canEdit: any, fls: any) {
        if (fls && fls.CU_CONTACT !== "N" && ["C", "F"].includes(fls.SECURITY)) {
            if (
                this.modelBean.itemTypeId === 4 &&
                canEdit &&
                fls.SPACE_SEARCH === "F" &&
                (fls.SPACE_PERM === "F" || fls.SPACE_SECURITY === "F")
            ) {
                this.securityLink = true;
            } else if (
                this.modelBean.itemTypeId === 2 &&
                canEdit &&
                fls.ACCOUNT_SEARCH === "F" &&
                (fls.CU_PERM === "F" || fls.ACCOUNT_SECURITY === "F")
            ) {
                this.securityLink = true;
            } else if (
                this.modelBean.itemTypeId === 6 &&
                canEdit &&
                fls.RESOURCE_SEARCH === "F" &&
                (fls.RESOURCE_PERM === "F" || fls.RESOURCE_SECURITY === "F")
            ) {
                this.securityLink = true;
            } else if (
                this.modelBean.itemTypeId === 1 &&
                fls.EVENT_SECURITY === "F" &&
                (fls.EVENT_PERM === "F" || (canEdit && fls.EVENT_SEARCH !== "N"))
            ) {
                this.securityLink = true;
            }
        }
    }

    getData(useServiceCache?: boolean, callback?: Function) {
        // usePagination is always false unless explicitly set to true when changing a page, and then it's set back to false
        if (this.modelBean.hasPagination && !this.usePagination) {
            this.modelBean.pages = null;
            this.modelBean.pageNum = 1;
            this.modelBean.pageKey = null;
        }
        this.usePagination = false;
        this.modelBean.useServiceCache = useServiceCache;
        this.modelBean.refreshF?.(callback);
        this.refreshed.emit();
        this.modelValueChange.emit(this.modelBean);
        this.changeDetector.detectChanges();
        return S25Util.delay(250).then(() => (this.modelBean.useCache = true));
    }

    // Method to refresh data (turns cache off to force a real refresh)
    async refreshData() {
        this.modelBean.useCache = false;
        if (this.modelBean.comptype === "cal_task_search" && this.modelBean.datePropertyValue) {
            this.modelBean.datePropertyValue === "first_date"
                ? (this.modelBean.hasAddtlTime = true)
                : (this.modelBean.hasAddtlTime = false);
        }
        await this.getData(false);
        if (this.modelBean.hasSearchChooser) await this.updateDropdown(); // Also update drop down list
    }

    async updateDropdown() {
        const searches = await OptService.getOptSearches(this.modelBean.comptype);
        this.modelBean.options = searches || null;
        SearchDropdownApi.refresh(this.elementRef.nativeElement, searches);
    }

    removeButtonClasses() {
        const elems = jSith.find(this.elementRef.nativeElement, Object.values(S25OptConst.viewButton2Class).join(", "));
        elems?.forEach((elem: Element) => elem.classList.remove(S25OptConst.activeViewClass));
    }

    setButtonClass(button: ViewButtonValue) {
        this.removeButtonClasses();
        if (!button) return;
        const buttonElem = jSith.findSingle(this.elementRef.nativeElement, S25OptConst.viewButton2Class[button]);
        buttonElem?.classList.add(S25OptConst.activeViewClass);
    }

    async setDatesOption(option: ViewButtonValue) {
        this.elementRef.nativeElement.blur();
        this.modelBean.datesOption.value = option;
        this.setButtonClass(option);
        this.disabledDays();
        this.dateViewChange.emit(option);
        await this.getData(false);
    }

    disabledDays() {
        const elems = jSith.find(this.elementRef.nativeElement, ".c-optionsBar__weeks-select");
        elems?.forEach((elem: Element) => elem.classList.remove("c-optionsBar__weeks-select--show"));
    }

    onEditableChange(editable: boolean) {
        this.modelBean.editable.value = editable;
        this.broadcast("s25OptEditableChanged", editable);
        jSith.timeout(async () => {
            // Wait for editable to be set, then refresh component
            // Set cache id to use for fetching the same results but in the new (edit or not-edit) mode
            this.modelBean.cacheIdOverride = editable && this.modelBean.lastCacheId;
            // Refresh component with editable flag set
            await this.getData();
            // Set cache override to undefined so that refresh, next-day, other page changes so not use this cache id and get their own
            this.modelBean.cacheIdOverride = undefined;
        });
    }

    onDatepickerChange(date?: Date) {
        if (this.modelBean.datesOption) this.modelBean.datesOption.value = null;
        this.modelBean.date = date;
        this.dateChange.emit(this.modelBean.date);
        this.changeDetector.detectChanges();
    }

    onUtilRateVizChange(data: { type: number }) {
        this.modelBean.utilRateViz.value = data;
        this.broadcast("s25OptUtilRateVizChanged", data);
        const views: OptUtilizationView[] = ["none", "RHC/EHC", "EHC/CAP", "RHC/CAP"];
        this.utilizationViewChange.emit(views[data.type]);
    }

    onEditToggle(state: boolean) {
        this.editModeChange.emit(state);
        if (this.modelBean.itemTypeId === Item.Ids.Location) this.spaceEditMode = state;
        this.changeDetector.detectChanges();
        return this.refreshData();
    }

    updateDateArrow = (direction: "back" | "forward") => {
        this.dateOptionsComponent?.updateDateArrow(direction);
    };

    broadcast(event: string, data: any) {
        this.modelBean?.broadcast?.(event, data);
    }

    onSearchChange(query: DropDownItem) {
        this.queryChange.emit("&" + query.val);
    }

    onMultiSearchChange(query: string) {
        this.modelBean.multiQueryStr = query;
        this.queryChange.emit(`&multi_query_id=${query}`);
    }

    onTabChange(tab: "availabilityWeekly" | "list" | "availability" | "calendar") {
        this.modelBean.visualizationClick?.(tab);
        this.tabChange.emit(tab);
    }

    setColChooseBean() {
        if (!this.columnChooser) return;
        this.modelBean.hasColumnChooser = true;
        this.modelBean.colChooseBean = {
            colList: this.columnChooser.columns.map((col) => ({
                prefname: col.id,
                name: col.name,
                isVisible: col.checked,
                isPermanent: col.permanent,
            })) as any,
            listColumnPrefName: this.columnChooser.prefName,
            updateCallback: this.updateColumns,
        };
    }

    @Bind
    updateColumns() {
        const checked = new Set(
            this.modelBean.colChooseBean.colList.filter((col) => col.isVisible).map((col) => col.prefname),
        );
        for (let col of this.columnChooser.columns) col.checked = checked.has(col.id);
        this.columnChooserChange.emit(this.columnChooser);
    }
}

export type OptUtilizationView = "none" | "RHC/EHC" | "EHC/CAP" | "RHC/CAP";
export type OptColumnChooser = {
    prefName: string;
    columns: { id: string; name: string; checked?: boolean; permanent?: boolean }[];
};
